import React from "react";
import "./Welcome.css";

function Welcome() {
  const handleRedirect = () => {
    window.location.href = "https://x.com/LeonardHolter17";
  };

  return (
    <div className="welcome-container">
      <div className="welcome-content">
        <h1>Welcome to Manus Models</h1>
        <p>
          Redefining the art of hand modeling with the power of artificial
          intelligence. Explore a new era of creativity and precision.
        </p>
        <button className="get-started-button" onClick={handleRedirect}>
          Journey
        </button>
      </div>
    </div>
  );
}

export default Welcome;
